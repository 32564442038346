import React, { useEffect, useMemo, useState } from "react";
import {
  createDocument,
  getAllData,
  updateDocumentByID,
} from "../../../apis/customApiHandler";
import { TD, TH, TR, Tbody, Thead } from "../../../styled/table";
import SelectField from "../../formik/SelectField";
import { Add, Delete } from "@mui/icons-material";
import Input from "../../../styled/input";
import { data } from "autoprefixer";

const Entry = ({
  entry,
  dataValue,
  entryIndex,
  fitmentFormik,
  onChange,
  tyreDetails,
  onSelectChange,
  segment,
  ...props
}) => {
  const [resetDisabled, setResetDisabled] = useState([]);
  const [location, setLocation] = useState([]);
  const [ configuration, setVehicleConfiguration] = useState([]);
  const [application, setApplication] = useState([]);
  const [loadSegment, setLoadSegment] = useState([]);
  const [testRequest, setTestRequest] = useState(null);
  const [fitmentAxle, setFitmentAxle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState([]);
  const [material, setMaterial] = useState([]);

  const getOptions = async () => {
    setLoading(true);
    // console.log("getting options");
    await getTestLocation();
    await getVehicleConfiguration();
    await getApplication();
    await getLoadSegment();
    await getFitmentAxle();
    setLoading(false);
  };

  useEffect(() => {
    // console.log("getting values'");
    getOptions();
  }, [props.productCategory]);

  // const getVehicleConfiguration = async () => {
  //   const data = await getAllData(
  //     "/master/vehicle/getVehicleConfiguration",
  //     {}
  //   );

  //   console.log('data1',data);
    

  //   const vehicleConfigurationData=dataValue?.vehicleDetails?.map((currEle)=>{
  //     return currEle
  //   })

  //   console.log('vehicleConfigurationData',dataValue?.vehicleDetails?.length);

  //   data?.vehicleDetails?.length===0?setVehicleConfiguration(data?.data):setVehicleConfiguration(vehicleConfigurationData)
  // };

  const getVehicleConfiguration = async () => {
  try {
    console.log("PRODCAT", props.productCategory)
    const response = await getAllData("/master/vehicle/getVehicleConfiguration", {
      productCategory: props.productCategory,
    });
    
    const vehicleConfigurationData = [...new Set(dataValue?.vehicleDetails?.map((currEle) => {
      return currEle.configuration;
    })?.filter(ele=>ele))]

    console.log('dataValue',dataValue, vehicleConfigurationData);

    if (!vehicleConfigurationData?.length) {
      setVehicleConfiguration(
        response?.data?.map((option) => ({
          label:
            option.make?.name + "/" + option.model?.name + "/" + option.name,
          value:
            option.make?.name + "/" + option.model?.name + "/" + option.name,
        }))
      );
    } else {
      setVehicleConfiguration(
        vehicleConfigurationData?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  } catch (error) {
    console.error('Error fetching vehicle configuration:', error);
  }
};


  const getApplication = async () => {
    const data = await getAllData("/master/other/getApplication", {});

    const applicationData=[...new Set(dataValue?.vehicleDetails?.map((currEle)=>{
      return currEle.application
    })?.filter(ele=>ele))]

    console.log("DG", data, applicationData, dataValue)

    !applicationData?.length
      ? setApplication(data?.data?.map(item => ({value: item.name, label: item.name})))
      : setApplication(applicationData?.map(item => ({label: item, value: item})));
  };
  const getTestLocation = async () => {
    const data = await getAllData("/master/test/getTestLocation", {});
    setLocation(
      data?.data?.map((option) => {
        return { label: option.name, value: option.name };
      })
    );
  };
  const getLoadSegment = async () => {
    const data = await getAllData("/master/other/getLoadSegment", {});
    setLoadSegment(data?.data);
  };
  const getFitmentAxle = async () => {
    const data = await getAllData("/master/vehicle/getFitmentAxle", {});
    setFitmentAxle(data?.data);
  };  

  const inputRow = [
    {
      name: "category",
      type: "select",
      label: "",
      onChange: onSelectChange,
      options: [
        { value: "FT", label: "FT" },
        { label: "PT", value: "PT" },
      ],
      required: true,
    },
    {
      name: "location",
      type: "select",
      label: "",
      options: location,
      required: true,
    },
    {
      name: "testMethod",
      type: "select",
      label: "",
      options: [
        { value: "Head On", label: "Head On" },
        { label: "Stand Alone", value: "Stand Alone" },
      ],
      required: true,
    },
    {
      name: "fitmentAxle",
      type: "select",
      label: "",
      options: fitmentAxle.map((option) => {
        return { label: option.name, value: option.name };
      }),
      required: true,
    },
    {
      name: "testTyre",
      type: "select",
      label: "",
      // onChange: onChange,
      onChange: onSelectChange,
      options: tyreDetails
        ?.filter((item) => item.tyre.testTyre == "JK")
        .map((item) => {
          return {
            label: item.tyre.group + ": " + item.tyre.groupDescription,
            value: item.tyre.group,
          };
        }),
      required: true,
    },
    {
      name: "testQuantity",
      type: "Number",
      tyreType: "testTyre",
      label: "",
      onChange: onChange,
      required: true,
      checkDisabled: (entryIndex, fitmentFormik) => {
        // console.log("Here", entryIndex, formik, value)
        // console.log("DIsabled", fitmentFormik.values.fitmentDetails[entryIndex])
        return fitmentFormik.values.fitmentDetails[entryIndex].testTyre == "";
      },
      min: 0,
    },
    {
      name: "bmTyre",
      type: "select",
      label: "",
      onChange: onSelectChange,
      options: tyreDetails
        ?.filter((item) => item.tyre.testTyre != "JK")
        ?.map((item) => {
          return {
            label: item.tyre.group + ": " + item.tyre.groupDescription,
            value: item.tyre.group,
          };
        }),
      required: true,
    },
    {
      name: "bmQuantity",
      type: "Number",
      label: "",
      tyreType: "bmTyre",
      onChange: onChange,
      checkDisabled: (entryIndex, fitmentFormik) => {
        // console.log("Here", entryIndex, formik, value)
        // console.log("DIsabled", fitmentFormik.values.fitmentDetails[entryIndex])
        return fitmentFormik.values.fitmentDetails[entryIndex].bmTyre == "";
      },
      required: true,
    },
    {
      name: "totalQuantity",
      type: "number",
      label: "",
      disabled: true,
      required: true,
    },
    {
      name: "vehicleQuantity",
      type: "number",
      label: "",
      required: true,
      disabled: (entryIndex, fitmentFormik) => {
        return (
          fitmentFormik.values.fitmentDetails[entryIndex].category === "PT"
        );
      },
    },
    {
      name: "segment",
      type: "text",
      label: "",
      value: segment,
      // options: loadSegment?.map((option) => {
      //   return { label: option.name, value: option.name };
      // }),
      required: true,
    },
    {
      name: "application",
      type: "select",
      label: "",
      options: application,
      required: true,
    },
    {
      name: "configuration",
      options: configuration,
      type: "select",
      label: "",
      required: true,
      onChange: (entryIndex) => (formik, value) => {
        fitmentFormik.setFieldValue(
          `fitmentDetails.${entryIndex}.configuration`,
          value.value
        );
        if (
          fitmentFormik.values.fitmentDetails[
            entryIndex
          ].fitmentAxle?.toLowerCase() === "all wheel"
        ) {
          const config = configuration?.filter(
            (item) => item.name === value.value
          )[0]?.numberOfWheels;
          // console.log(
          //   { config },
          //   configuration?.filter((item) => item.name === value.value)[0]
          // );
          fitmentFormik.setFieldValue(
            `fitmentDetails.${entryIndex}.vehicleQuantity`,
            0
          );
        }
      },
    },
  ];  

  const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change,(on normal onWheel (return false) it works onFocus also)
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}

  return loading || !fitmentFormik?.values ? (
    <div className="justify-center mx-auto flex">Preparing</div>
  ) : (
    <TR className="relative">
      {inputRow.map((field, index) => {
        return field.type === "select" ? (
          <SelectField
            {...entry}
            {...field}
            onChange={
              field.onChange
                ? field.onChange(entryIndex, field.name)
                : undefined
            }
            disabled={
              typeof field.disabled === "function"
                ? field.disabled(entryIndex, fitmentFormik)
                : field.disabled
            }
            className={`text-sm w-[10rem] ${field.className}`}
            formik={fitmentFormik}
            key={`fitmentDetails[${entryIndex}].${field.name}`}
            name={`fitmentDetails[${entryIndex}].${field.name}`}
            label={field.label}
            options={field.options}
            required={field.required}
            multiple={false}
            halfCol={field.halfCol}
          />
        ) : (
          <div className="py-1">
            <Input
              type={field.type}
              onWheel={numberInputOnWheelPreventChange}
              label={field.label}
              required={field.required}
              name={`fitmentDetails[${entryIndex}].${field.name}`}
              onChange={(e) => {
                e.preventDefault();
                if (field.onChange)
                  field.onChange(
                    entryIndex,
                    field.name,
                    e.target.value,
                    field.tyreType
                  );
                else fitmentFormik.handleChange(e);
              }}
              value={
                fitmentFormik?.values.fitmentDetails[entryIndex][field.name]
              }
              disabled={
                field.checkDisabled
                  ? field.checkDisabled(entryIndex, fitmentFormik)
                  : typeof field.disabled === "function"
                  ? field.disabled(entryIndex, fitmentFormik)
                  : field.disabled
              }
              formik={fitmentFormik}
              className="px-2 w-[10rem] justify-start items-start"
            />
            <div className="text-red-500 w-36">
              {fitmentFormik?.errors?.fitmentDetails &&
                fitmentFormik?.errors?.fitmentDetails[entryIndex] &&
                fitmentFormik?.errors?.fitmentDetails[entryIndex][field.name]}
            </div>
          </div>
        );
      })}
      <TD className="text-center justify-center  items-center">
        {" "}
        <div
          className="border border-1 border-gray-200 rounded-md w-10 p-1 mx-auto"
          onClick={() => {
            const fitmentDetailsCopy = [...fitmentFormik.values.fitmentDetails];
            onChange(entryIndex, "testQuantity", 0, "testTyre");
            onChange(entryIndex, "bmQuantity", 0, "bmTyre");

            // fitmentFormik.setFieldValue("calculatedTyreDetails", tyreDetailsCopy);
            fitmentFormik.setFieldValue(
              "fitmentDetails",
              fitmentDetailsCopy.filter((entry, index) => index !== entryIndex)
            );
          }}
        >
          <Delete />
        </div>{" "}
      </TD>
    </TR>
  );
};

export default Entry;