const INITIAL_STATE = {
  sideBarState: true,
};

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIDEBAR_OPEN':
      return {
        ...state,
        sideBarState: true,
      };
    case 'SIDEBAR_CLOSE':
      return {
        ...state,
        sideBarState: false,
      };
    default:
      return state;
  }
};

export default sidebarReducer;

export const sideBarOpen = () => ({
  type: 'SIDEBAR_OPEN',
});

export const sideBarClose = () => ({
  type: 'SIDEBAR_CLOSE',
});
